const ADD_USER = 'ADD_USER'
const REMOVE_USER = 'REMOVE_USER'

export const addUser = (user) => {
    return { type: ADD_USER, payload: user }
}
export const removeUser = (id) => {
    return { type: REMOVE_USER, payload: {id} }
}

const initialState = []

export const usersReducer = (state = initialState,action) => {
    const { type, payload } = action
    const { users } = state

    switch (type) {
        case ADD_USER: {
            // state without actual user (for updating his data and not duplicating)
            var newState = state.filter(x => x.id != payload.id);
            return [
                ...newState,
                payload
            ]
        }
        case REMOVE_USER: {
            return state.filter(x => x.id != payload.id)
        }

        default:
            return state
    }
}