import Guid from 'guid'
import Helpers from '../utils/Helpers'
import { EnumSaleStatus } from '../constants/Enums';

const ADD_SALE = 'ADD_SALE'
const SET_SALE = 'SET_SALE'
const SET_NOTA_DATA = 'SET_NOTA_DATA'
const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA'
const SET_ALL_SALES = 'SET_ALL_SALES'
const CANCEL_SALE = 'CANCEL_SALE'

export const addSale = s => ({
  type: ADD_SALE,
  payload: s
})
export const setSale = (saleGuid, sale) => ({
  type: SET_SALE,
  payload: { saleGuid, sale }
})
export const setAllSales = (salesObj) => ({
  type: SET_ALL_SALES,
  payload: salesObj
})
export const cancelSale = (saleGuid) => dispatch => {
  dispatch({
    type: CANCEL_SALE,
    payload: { saleGuid }
  })
  return Promise.resolve();
}
export const setNotaData = (saleGuid, notaData) => dispatch => {
  dispatch({
    type: SET_NOTA_DATA,
    payload: { saleGuid, notaData }
  })
  return Promise.resolve();
}
export const setCustomerData = (saleGuid, customerData) => ({
  type: SET_CUSTOMER_DATA,
  payload: { saleGuid, customerData }
})

const addSaleReducer = (state, sale) => {
  if (!sale.guid) sale.guid = Guid.raw();
  //sale data
  sale.soldDate = new Date();
  sale.synced = false;
  sale.status = EnumSaleStatus.Confirmed;
  // objects to arrays on close sale to sync to server better
  sale.payments = Helpers.ObjToArray(sale.payments)
  sale.products = Helpers.ObjToArray(sale.products)
  // sales obj state cleanup:
  var newState = clearOldSales({ ...state })
  // add new sale
  newState[sale.guid] = sale;
  return newState;
}

const clearOldSales = (salesObj) => {
  var arrSales = Helpers.ObjToArray(salesObj);
  var daysAgo = new Date();
  daysAgo=daysAgo.addDays(-5);
  arrSales = arrSales.filter(x => new Date(x.soldDate) > daysAgo || x.dateSync==null);
  var newSales = {};
  arrSales.map(x => newSales[x.guid] = x);
  return newSales;
}

export const getSale = (sales = {}, saleGuid) => {
  return sales[saleGuid] || {};
}

const initialState = {}

export function salesReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case ADD_SALE:
      return addSaleReducer(state, payload)
    case SET_SALE:
      var newState = { ...state };
      var sale = { ...(getSale(newState, payload.saleGuid)) };
      sale = { ...sale, ...(payload.sale) };
      newState[payload.saleGuid] = sale;
      return newState;
    case SET_ALL_SALES:
      var newState = { ...payload };
      return newState;
    case CANCEL_SALE:
      var newState = { ...state };
      var sale = { ...(getSale(newState, payload.saleGuid)) };
      sale.status = EnumSaleStatus.Canceled;
      sale.nf = {};
      newState[payload.saleGuid] = sale;
      return newState;
    case SET_NOTA_DATA:
      var newState = { ...state };
      var sale = { ...(getSale(newState, payload.saleGuid)) };
      sale.nf = payload.notaData;
      newState[payload.saleGuid] = sale;
      return newState;
    case SET_CUSTOMER_DATA:
      var newState = { ...state };
      var sale = { ...(getSale(newState, payload.saleGuid)) };
      sale.customer = payload.customerData;
      newState[payload.saleGuid] = sale;
      return newState;
    default:
      return state
  }
}