const SET_USER_AUTH = 'SET_USER_AUTH';

export const setUserAuth = (auth) => {
    return { type: SET_USER_AUTH, payload: auth }
}

export const logout = () => {
    return { type: SET_USER_AUTH, payload: {} }
}

const initialState = {
    userAuth: {}
}

export const authReducer = (state = initialState,action) => {
    const { type, payload } = action

    switch (type) {
        case SET_USER_AUTH: {
            return {
                ...state,
                userAuth: payload
            }
        }

        default:
            return state
    }
}